import React from 'react'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import * as Sentry from '@sentry/browser'
import createSentryMiddleware from 'redux-sentry-middleware'
import reducer from './reducer'
import produce from 'immer'

const composeEnhancers =
  process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

const configureStore = () => {
  const store = createStore(
    reducer,
    composeEnhancers(
      applyMiddleware(
        thunk,
        createSentryMiddleware(Sentry, {
          actionTransformer: ({ type }) => ({ type })
        })
      )
    )
  )

  if (module.hot && process.env.NODE_ENV === 'development') {
    module.hot.accept('./reducer', () => {
      store.replaceReducer(reducer)
    })
  }

  return store
}

const initialImmerState = {
  db: {
    alert: {},
    config: {},
    ad: {},
    me: null,
    me_authenticated: null,
    me_profile: undefined
  },
  ui: {
    dashboard: {},
    alert_list: {},
    left_mobile_menu_open: false,
    modal_app_install_open: false,
    modal_app_chasseloc_open: false,
    modal_create_ad_open: false
  }
}
export const ImmerStateContext = React.createContext(initialImmerState)
export const ImmerUpdateContext = React.createContext(null)
export function useImmerStore() {
  return [React.useContext(ImmerStateContext), React.useContext(ImmerUpdateContext)]
}
export function ImmerStoreProvider({ children }) {
  const [state, updateState] = React.useReducer(produce, initialImmerState)
  return (
    <ImmerUpdateContext.Provider value={updateState}>
      <ImmerStateContext.Provider value={state}>{children}</ImmerStateContext.Provider>
    </ImmerUpdateContext.Provider>
  )
}

export default configureStore
