import React from 'react'
import { withRouter, Route } from 'react-router-dom'
import { compose, withProps } from 'recompose'
import { createSelector } from 'reselect'
import { SnackbarProvider } from 'notistack'

import routes from '../routes'
import { router_pathname_selector } from '../store/reducers/router'
import LayoutFooter from './Layout/Footer'
import LayoutHeader from './Layout/Header'
import LayoutOwner from './Layout/Owner'
import LayoutRenter from './Layout/Renter'
import LayoutMobileBanner from './Layout/MobileBanner'
import LayoutJinkaBanner from './Layout/JinkaBanner'
import { Box } from './Shared/Base'
import LayoutModalAppInstall, {
  LayoutModalAppChasseloc,
  LayoutModalCreateAd
} from './Layout/ModalAppInstall'

const route_matched_layout_params_selector = createSelector(
  router_pathname_selector,
  pathname => {
    const matched_route_key = Object.keys(routes).find(rkey => routes[rkey].route.match(pathname))

    if (matched_route_key) {
      return {
        header_footer: routes[matched_route_key].header_footer === false ? false : true, // default true
        menu_wrapper: routes[matched_route_key].menu,
        layout_mw: routes[matched_route_key].layout_mw || 1100,
        hide_signin: routes[matched_route_key].hide_signin,
        root_classname: routes[matched_route_key].root_classname
      }
    }

    return {
      header_footer: true,
      menu_wrapper: null,
      layout_mw: 1100,
      hide_signin: false,
      root_classname: ''
    }
  }
)

const Container = ({ menu_wrapper, children }) => {
  switch (menu_wrapper) {
    case 'renter':
      return <LayoutRenter>{children}</LayoutRenter>

    case 'owner':
      return <LayoutOwner>{children}</LayoutOwner>

    default:
      return children
  }
}

const Layout = ({
  children,
  has_header_footer,
  menu_wrapper,
  layout_mw,
  hide_signin,
  location,
  root_classname
}) => {
  const classname = root_classname ? root_classname : location.pathname.replace('/', '_')
  return (
    <SnackbarProvider
      maxSnack={3}
      ContentProps={{
        style: {
          fontSize: '1.6rem',
          backgroundColor: '#E5F8E3',
          justifyContent: 'center',
          color: '#6EA668'
        }
      }}
      dense
      hideIconVariant
      preventDuplicate
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <Route
        path={[routes.alert_result.route.reverse(), routes.ad.route.reverse({ id: ':id' })]}
        exact
        component={LayoutMobileBanner}
      />
      <Route path={[routes.homepage.route.reverse()]} exact component={LayoutJinkaBanner} />
      <LayoutModalAppInstall />
      <LayoutModalAppChasseloc />
      <LayoutModalCreateAd />
      <Box
        display="grid"
        gridTemplateColumns="minmax(0px, 1fr)"
        gridTemplateRows="auto 1fr auto"
        minHeight="100vh"
        className={classname}
      >
        {has_header_footer && <LayoutHeader layout_mw={layout_mw} hide_signin={hide_signin} />}
        <div>
          <Container menu_wrapper={menu_wrapper}>{children}</Container>
        </div>
        {has_header_footer && <LayoutFooter layout_mw={layout_mw} hide_stores={hide_signin} />}
      </Box>
    </SnackbarProvider>
  )
}

const enhance = compose(
  withRouter,
  // do not connect to state pathname bacause it is in advance compare to react-router
  withProps(({ location }) => ({
    has_header_footer: route_matched_layout_params_selector({ router: { location } }).header_footer,
    menu_wrapper: route_matched_layout_params_selector({ router: { location } }).menu_wrapper,
    layout_mw: route_matched_layout_params_selector({ router: { location } }).layout_mw,
    hide_signin: route_matched_layout_params_selector({ router: { location } }).hide_signin,
    root_classname: route_matched_layout_params_selector({ router: { location } }).root_classname
  }))
)

export default enhance(Layout)
