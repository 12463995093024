import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import LoadingBar from '../Shared/LoadingBar'
import { qps_from_search } from '../../helpers/misc'
import { useFetchApi, useCheckAuthentication } from '../Shared/hooks'
import routes from '../../routes'

const SignConfirm = () => {
  const location = useLocation()
  const fetcher = useFetchApi()
  const check_authentication = useCheckAuthentication()
  const history = useHistory()
  const location_search = location.search
  const { confirm_token, is_from_mobile, is_from_particulier } = qps_from_search(location_search)

  React.useEffect(() => {
    const init = async () => {
      await fetcher(`user/confirm`, { method: 'POST', body: JSON.stringify({ confirm_token }) })
      await check_authentication()

      if (is_from_particulier) {
        window.location.href = 'https://particulier.jinka.fr/annonce/create'
        return
      }

      if (is_from_mobile) {
        history.replace(routes.asrenter_user_email_mobile_confirm.route.reverse())
      } else {
        history.replace(routes.user_redirector.route.reverse())
      }
    }

    init()
  }, [check_authentication, confirm_token, fetcher, history, is_from_mobile, is_from_particulier])

  return <LoadingBar />
}

export default SignConfirm
